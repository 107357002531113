import React from 'react';
import {
  ControllerRenderProps,
  FieldValues,
  Path
} from 'react-hook-form/dist/types';
import { Flex } from '../Flex';
interface Props<T extends FieldValues> {
  name: string;
  type?: string;
  placeholder?: string;
  className?: string;
  errors?: Record<string, unknown>;
  field?: ControllerRenderProps<T, Path<T>>;
  icon?: React.ReactNode;
  disabled?: boolean;
  value?: string | number;
  ref?: React.Ref<HTMLInputElement>;
  defaultValue?: string | number;
  onClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const TextField = <T extends FieldValues>({
  name,
  type,
  placeholder,
  className,
  errors,
  field,
  icon,
  value,
  disabled = false,
  onClick,
  defaultValue,
  ref,
  onChange,
  ...props
}: Props<T>): JSX.Element => {
  return (
    <div className="w-full">
      <input
        defaultValue={defaultValue}
        ref={ref}
        onChange={onChange}
        id={name}
        type={type}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onClick={onClick}
        className={`input border rounded-lg shadow-md  ${
          errors?.[name] ? 'border-red-500' : 'border-gray-300'
        } ${className}`}
        {...props}
        {...field}
      />
      {icon && (
        <Flex className="absolute inset-y-0 right-3" align="center">
          {icon}
        </Flex>
      )}
    </div>
  );
};
