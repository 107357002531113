import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path
} from 'react-hook-form';
import { Flex } from '../Flex';
import InputMask, {
  BeforeMaskedStateChangeStates,
  InputState
} from 'react-input-mask';

import { TextField } from '../TextField';

interface FormTextFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  placeholder?: string;
  alwaysShowMask?: boolean;
  type?: string;
  errors: FieldErrors<T>;
  label?: string;
  className?: string;
  mask?: string;
  maskChar?: string | null;
  beforeMaskedStateChange?: (
    states: BeforeMaskedStateChangeStates
  ) => InputState;
}

const FormTextField = <T extends FieldValues>({
  control,
  name,
  placeholder,
  type = 'text',
  errors,
  label,
  className,
  mask,
  maskChar,
  alwaysShowMask = true,
  beforeMaskedStateChange,
  ...props
}: FormTextFieldProps<T>) => {
  return (
    <Flex direction="col" className="gap-1 w-full">
      {label && (
        <label
          htmlFor={name as string}
          className="font-source-sans-pro font-semibold mb-2"
        >
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field }) =>
          mask ? (
            <InputMask
              mask={mask}
              maskChar={maskChar}
              alwaysShowMask={alwaysShowMask}
              beforeMaskedStateChange={beforeMaskedStateChange}
              {...field}
            >
              <TextField
                name={name}
                type={type}
                placeholder={placeholder}
                {...props}
                className={`input border rounded-lg shadow-md ${
                  errors[name] ? 'border-red-500' : 'border-gray-300'
                } ${className}`}
                errors={errors}
              />
            </InputMask>
          ) : (
            <TextField
              name={name}
              type={type}
              placeholder={placeholder}
              {...props}
              className={`input border rounded-lg shadow-md ${
                errors[name] ? 'border-red-500' : 'border-gray-300'
              } ${className}`}
              errors={errors}
              field={field}
            />
          )
        }
      />
      {errors[name] && (
        <span className="mt-1 text-xs text-red-500">
          {String(errors[name]?.message)}
        </span>
      )}
    </Flex>
  );
};

export default FormTextField;
